<!--
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-09-10 09:43:36
 * @LastEditTime: 2019-09-29 14:49:19
 * @LastEditors: Please set LastEditors
 -->
<template>
    <div class="myncb">
        <p class="myncb_tit"><i class="el-icon-message"></i>个人邮箱认证</p>
        <p class="myncb_con"><span>*</span>邮箱:<input type="text" placeholder="请输入常用邮箱" v-model="email"><el-tooltip v-if="email_flag==1" class="item" effect="dark" content="已认证" placement="top">
            <i class="el-icon-circle-check"></i>
        </el-tooltip></p>
        <p class="myncb_zhushi">我们会向您邮箱发送一封邮件用于认证您的邮箱<br />请在有效期60分钟内完成</p>
        <p class="myncb_btn" @click="dianji_queding">确定</p>
    </div>
</template>

<script>
import { get_my_info, send_check_user_email } from '../../api/api.js'
export default {
  name: 'myncb',
  data () {
    return {
      email: '',
      email_flag: '0'
    }
  },
  mounted () {
  },
  created () {
    this.jichu()
  },
  methods: {
    jichu () {
      this.email = this.$jichuxinxi().email
      console.log(this.$jichuxinxi().email_flag)
      // 获取我的基础信息
      get_my_info({
        data: {
          user_id: this.$jichuxinxi().user_id
        }
      }).then(ras => {
        console.log(ras)
        if (ras.data.code == 10069) {
          const date = JSON.parse(ras.data.body.data)
          this.email_flag = date.email_flag == 1 ? '1' : '0'
          sessionStorage.setItem('pc_mlbb_jichuxinxi', JSON.stringify(ras.data.body.data))
        } else if (ras.data.code == 10070) {}
      })
    },
    dianji_queding () {
      if (this.email.length != 0) {
        send_check_user_email({
          data: {
            email: this.email,
            user_id: this.$jichuxinxi().user_id
          }
        }).then(res => {
          console.log(res)
          if (res.data.code == 10073) {
            this.$message({
              message: '发送成功，请及时查收',
              type: 'success'
            })
          } else if (res.data.code == 10074) {
            this.$message({
              message: '发送失败，请稍后重试',
              type: 'error'
            })
          } else if (res.data.code == 10084) {
            this.$message({
              message: '已认证',
              type: 'warning'
            })
          }
        })
      } else {
        this.$message({
          message: '未填完基础信息',
          type: 'warning'
        }) 
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
 @import './geren.scss'
</style>
